import detailsElementPolyfill from 'details-element-polyfill';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import FormValidation from './formLead.js';

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

/* ----------------------------------------------------------------------------
global vars
---------------------------------------------------------------------------- */
//table of contents (toc) nav item array
let tocNavArr = [];

//main nav item array
let mainNavArr = [];

//section article item array
let sectionArticleArr = [];

//intersection observer
let observer = null;


/* ----------------------------------------------------------------------------
hamburger handler
---------------------------------------------------------------------------- */
let hamburger = $(".hamburger");
hamburger.on("click", function(e) {
    hamburger.toggleClass("is-active");
    $("#mainNav").toggleClass("is-active");
});


/* ----------------------------------------------------------------------------
navigation item click handler
---------------------------------------------------------------------------- */
//handler for clicking a TOC or main nav anchor
function updateActiveNav(activeElem){
	//remove all active classes
	$("#mainNav a").each(function(){
		$(this).removeClass("active");
	});
	
	//find the nav item clicked on by its index so that either nav can be used to trigger
	
	let mainNavTarget = $("#mainNav li").eq($(activeElem).parent().index()).find("a")[0];
	//add active class to main nav item at the proper index
	$(mainNavTarget).addClass("active");

	document.querySelector('#'+$(activeElem).attr("href").split("#")[1]).scrollIntoView({ behavior: 'smooth' });
}

/* ----------------------------------------------------------------------------
wire navigation items - register intersection observer
---------------------------------------------------------------------------- */
function wireNav(){
	//create interesection observer
	let options = {
	  	root: null,
	  	rootMargin: '150px',
		threshold: .15
	}

	observer = new IntersectionObserver(handleObserver, options);

	//add element references to array
	$("#tocNav a").each(function(){
		tocNavArr.push($(this));
	});
	$("#mainNav a").each(function(){
		mainNavArr.push($(this));
	});

	//add click event to navigation items
	$("#mainNav a, #tocNav a").on("click",function(e){
		updateActiveNav(e.target);
	});

	//place main sections and articles in array
	$("#page .section").each(function(){
		sectionArticleArr.push($(this));
		//observe page sections
		observer.observe(document.querySelector("#podcastSection"));
		observer.observe(document.querySelector("#fund"));
		observer.observe(document.querySelector("#tributes"));
		observer.observe(document.querySelector("#contact"));
		//observe top pageHeader
		observer.observe(document.querySelector("#mainNav"));
	});
}

/* ----------------------------------------------------------------------------
intersection observer handler
---------------------------------------------------------------------------- */
function handleObserver(entries, observer) {
	entries.forEach(entry => {
	    // Each entry describes an intersection change for one observed
	    // target element:
	    //   entry.boundingClientRect
	    //   entry.intersectionRatio
	    //   entry.intersectionRect
	    //   entry.isIntersecting
	    //   entry.rootBounds
	    //   entry.target
	    //   entry.time
	
	    if(entry.target.getAttribute('id') === "mainNav"){
	    	if(entry.isIntersecting === false){
	    		handleTOCNavDisplay(true);
	    	}else{
	    		handleTOCNavDisplay(false);
	    	}
	    }else{
		    if(entry.isIntersecting === true){
		    	//find matching nav items based on the id of the section article we have found
				handleNavUpdates(entry.target.getAttribute('id'),true);
		    }else{
		    	handleNavUpdates(entry.target.getAttribute('id'),false)
		    }
		}
	  });
}

/* ----------------------------------------------------------------------------
handle nav update based on results from intersection observer
---------------------------------------------------------------------------- */
function handleNavUpdates(id,active) {
	let activeID = "";

	if(id === "episodes" || id === "players" || id === "subscribe" || id === "podcastSection"){
		//override ids tht are podcast related
		activeID = "podcast";
	}else{
		activeID = id;
	}

	switch(active){
		case true:
			$("#tocNav a[href *=" + activeID + "]").addClass("active");
			$("#tocNav a[href *=" + activeID + "]").parent().addClass("active");
			// $("#mainNav a[href *=" + id + "]").addClass("active");
			break;
		case false:

			$("#tocNav a[href *=" + activeID + "]").removeClass("active");
			$("#tocNav a[href *=" + activeID + "]").parent().removeClass("active");
			// $("#mainNav a[href *=" + id + "]").removeClass("active");
			break;
	}


}

/* ----------------------------------------------------------------------------
handle TOC nav display based on results from intersection observer
---------------------------------------------------------------------------- */
function handleTOCNavDisplay(b){
	switch(b){
		case true:
			$("#tocNav").addClass("active");
			$("#page").addClass("toc-active");
			break;
		case false:
			$("#tocNav").removeClass("active");
			$("#page").removeClass("toc-active");
			break;
	}
}


/* ----------------------------------------------------------------------------
wire contact form for validation and submission
---------------------------------------------------------------------------- */
function formSubmittedCallback(response){
	if(response === 200){
		$("#contactForm").hide();
		$("#contactMessage").hide();
		$("#contactThanks").show();
		document.getElementById('contactThanks').scrollIntoView({ behavior: 'smooth' });
	}
}
function wireForm(){
	//the functions called here originate from formLead.js
	$("#contactForm").submit(function(e){
        e.preventDefault();
        if(FormValidation.isFormValid("contactForm","formSummary")){
        	$("#contactForm").find(':submit').attr('disabled','disabled');
	        grecaptcha.ready(function() {
	          grecaptcha.execute('6Ld4jCQaAAAAAJkmBzJxBPlHk_3g0pdj7XyqI6Ig', {action: 'submit'}).then(function(token) {
	          	$("#contactFormToken").val(token);
	          	FormValidation.processForm("contactForm","formSummary","/services/contactengine.php",formSubmittedCallback);
	          });
	        });
        }
    });
}

$(function() {
    wireNav();
    wireForm();
});
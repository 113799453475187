export default class FormValidation{
	/* ----------------------------------------------------------------------------
	validate a given form
	---------------------------------------------------------------------------- */
	static isFormValid(formID,formSummaryID) {
		var isValid = true,
			formData = $("#" + formID).serializeArray(),
			validationErrors = [],
			$formSummary = $("#"+formSummaryID);

		//Clear out the form summary
		$formSummary.slideUp('fast');
		$(".required", $("#" + formID)).removeClass("invalid");

		for(var i=0; i < formData.length; i++) {
			var $inputElement = $("[name=" + formData[i].name + "]", $("#" + formID));
	 
			if($inputElement.hasClass("required")) {
				//Check all fields required fields are filled out first
				if(formData[i].value === "") {
					isValid = false;
					$inputElement.addClass("invalid");
					validationErrors.push($inputElement.attr("data-name") + " is required");
				} else {
					//Check email address is valid
					if(formData[i].name === "email") {
						var regEmail = new RegExp(/^[a-z0-9_-]+(\.[-_a-z0-9]+)*@[a-z0-9-_]+(\.[a-z0-9-_]+)*(\.[a-z]{2,15})$/),
							emailValid = regEmail.test(formData[i].value.toLowerCase());
						if(!emailValid) {
							isValid = false;
							$("[name=" + formData[i].name + "]", $("#" + formID))
								.addClass("invalid");
							validationErrors.push("Please enter a valid email address.");
						}
					}
				}
			}
		}

		//Update the form summary object
		$formSummary
			.removeClass("success error")
			.empty(); 
		if(!isValid) {
			var errorList = '';

			errorList = $("<ul />")
							.attr({"class":"errorList"})
							.appendTo($formSummary);

			for(i=0; i < validationErrors.length; i++) {
				$("<li />")
					.text(validationErrors[i])
					.appendTo(errorList);
			}
			$formSummary
				.addClass("error")
				.slideToggle()
		}
		return isValid;
	}

	/* ----------------------------------------------------------------------------
	process a given form
	---------------------------------------------------------------------------- */
	static processForm(formID,formSummaryID,processURL,callbackFunction) {
		var targetFormStr = formID;
		var targetProcessURL = processURL;
		var $formSummary = $("#"+formSummaryID);	

		$.ajax({
	        url: targetProcessURL,
	        data: $('#'+targetFormStr).serialize(),
	        dataType : 'json',
	        type: 'POST',
	        success: function(response) {
	        	var statusCode = response.status;
	        	callbackFunction(statusCode);
	        },
	        error: function (jqXHR, textStatus, errorThrown ) {
	            console.log(jqXHR);
	            console.log(textStatus);
	            console.log(errorThrown);

	            $("<p />")
					.attr({"class":"error"})
					.text("There was a problem sending your message. Please contact the site administrator.")
					.appendTo($formSummary);

				$formSummary
					.slideToggle()
					.delay(5000)
					.slideToggle()
					.removeClass("error")
					.removeClass("success");
	    	}
		});
	}
}